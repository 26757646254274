<template>
  <div>
    <div class="question-Box">
      <div class="question-Passage mb-2" v-if="myPassageContent">
        <el-card>
          <h2 class="title">Passage</h2>
          <div @mouseup="getSelectText" v-html="myPassageContent"></div>
        </el-card>
      </div>
      <div
        :style="
          !passageContent
            ? 'width:100%;flex:1'
            : 'height: auto !important;flex:1'
        "
        class="question-Question"
      >
        <el-card class="mb-2">
          <h2 class="title">Question</h2>
          <div class="page-content p-0 mb-4" style="height: auto !important;">
            <div class="myquestion">
              <p>
                <span class="myquestion-no">
                  <b>{{ questionOrder }}. </b>
                </span>
                <span v-html="content"></span>
              </p>
              <div
                class="form-check"
                v-for="(option, optionIndex) in options"
                :key="option.order"
              >
                <div
                  :class="
                    question.maskArr.indexOf(option.letter) > -1
                      ? 'form-check-hide'
                      : ''
                  "
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="questionOrder"
                    :id="optionIndex"
                    :value="option.label"
                    :checked="myAnswer == option.label"
                    :disabled="true"
                  />
                  <label
                    :class="
                      question.crossOutArr.indexOf(option.label) > -1
                        ? 'strike form-check-label elim-option'
                        : 'form-check-label elim-option'
                    "
                    :for="optionIndex"
                    @click="onChangeOption(option.label)"
                  >
                    {{ getLetter(questionOrder, optionIndex) }}.
                    <span v-html="option.content"></span>
                  </label>
                </div>
              </div>
              <el-alert
                :type="isCorrect ? 'success' : 'error'"
                class="mt-2"
                show-icon
                :closable="false"
              >
                <div slot="title">
                  <div class="mb-2">
                    <b>{{
                      `Your Answer: ${
                        answer ? getAnswerLetter(questionOrder, answer) : ""
                      }`
                    }}</b>
                  </div>
                  <div>
                    <b>{{
                      `Correct Answer: ${getAnswerLetter(
                        questionOrder,
                        correctAnswer
                      )}`
                    }}</b>
                  </div>
                </div>
              </el-alert>
            </div>
          </div>

          <div
            v-for="(tag_analysis, key, index) in questionTags(tags)"
            :key="index"
            class="mb-2"
          >
            <small>
              <b>{{ key }}: </b>
            </small>
            <el-tooltip
              v-for="tag in tag_analysis"
              :key="tag.id"
              class="item pb-2 mr-2"
              effect="dark"
              :content="tag.type"
              placement="top"
            >
              <el-tag type="success" size="small" class="ivy-tag">
                <b>{{ tag.name }}</b>
              </el-tag>
            </el-tooltip>
          </div>
        </el-card>
        <el-card class="mb-2" v-if="activities && activities.length > 0">
          <h2 class="title">Answer Timeline</h2>
          <TimeLine
            class="mb-4"
            :activities="activities"
            :correctAnswer="correctAnswer"
            :questionOrder="questionOrder"
          ></TimeLine>
        </el-card>
        <el-card class="mb-2">
          <h2 class="title">Explanation</h2>
          <div v-html="explanation"></div>
          <AI
            class="mt-2"
            :content="`Explanation`"
            :questionId="questionId"
          ></AI>
          <div class="AI-Explanation">
            <div class=""></div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import $ from "jquery";
import TimeLine from "@/views/act/actResult/components/TimeLine";
import AI from "@/views/act/actResult/components/AI";
import _ from "lodash";

export default {
  metaInfo() {},

  components: { TimeLine, AI },

  mixins: [],

  props: {
    questionId: {
      type: Number,
      default: null
    },
    canHighlighter: {
      type: Boolean,
      default: false
    },
    canEliminator: {
      type: Boolean,
      default: false
    },
    activities: {
      type: Array,
      default: () => []
    },
    canMasking: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "test",
      validator: value => ["test", "explanation"].includes(value)
    },
    questionOrder: {
      type: Number,
      default: null
    },
    tags: {
      type: Array,
      default: () => []
    },
    content: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    answer: {
      type: String,
      default: ""
    },
    passageIntro: {
      type: String,
      default: ""
    },
    passageContent: {
      type: String,
      default: ""
    },
    correctAnswer: {
      type: String,
      default: ""
    },
    explanation: {
      type: String,
      default: ""
    },
    isCorrect: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      question: {
        crossOutArr: [],
        maskArr: []
      },
      myPassageContent: null,
      myAnswer: null
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {
    currentZoom() {
      $(".wrapper").animate({ zoom: this.currentZoom }, "slow");
    },
    questionOrder() {
      this.myAnswer = this.answer;
    },
    answer(value) {
      this.myAnswer = JSON.parse(JSON.stringify(value));
    },
    passageContent(value) {
      console.log(value);
      this.myPassageContent = JSON.parse(JSON.stringify(value));
    }
  },

  mounted() {
    this.myAnswer = JSON.parse(JSON.stringify(this.answer));
    this.myPassageContent = JSON.parse(JSON.stringify(this.passageContent));
  },

  methods: {
    questionTags(tags) {
      let questionTags = null;
      if (tags) {
        questionTags = _.groupBy(tags, "type");
      }
      return questionTags;
    },
    getAIExplanation(id) {},
    reSetPassage() {
      // console.log(this.myPassageContent);
      // this.myPassageContent = JSON.parse(JSON.stringify(this.passageContent));
      $("span.highlight").each(function() {
        $(this).removeClass("highlight");
      });
    },
    getAnswerLetter(questionOrder, index) {
      // console.log(questionOrder);
      // console.log(index);
      const oddLetters = ["A", "B", "C", "D", "E"];
      const evenLetters = ["F", "G", "H", "J", "K"];
      if (questionOrder % 2 === 1) {
        return index;
      } else {
        let i = oddLetters.indexOf(index);
        return i > -1 ? evenLetters[i] : "-";
      }
    },
    getLetter(questionOrder, index) {
      const oddLetters = ["A", "B", "C", "D", "E"];
      const evenLetters = ["F", "G", "H", "J", "K"];
      if (questionOrder % 2 === 1) {
        return oddLetters[index];
      } else {
        return evenLetters[index];
      }
    },
    getSelectText() {
      if (this.canHighlighter) {
        this.replaceSelection();
      }
    },
    replaceSelection() {
      if (window.getSelection) {
        let sel = window.getSelection();
        let selectStr = sel.toString();
        let ranges = [];
        if (selectStr.trim != "") {
          for (var i = 0; i < sel.rangeCount; i++) {
            ranges[i] = sel.getRangeAt(i);
          }
          let range = sel.getRangeAt(0);
          let temp = "";
          range.deleteContents();
          for (let i = 0; i < selectStr.length; i++) {
            console.log([selectStr[selectStr.length - 1 - i], selectStr[i]]);
            let text = selectStr[selectStr.length - 1 - i];
            if (text === " ") {
              text = "&nbsp;";
            }
            temp = `<span class="highlight-span highlight">${text}</span>`;
            range.insertNode($(temp)[0]);
          }
        }
      } else if (document.selection) {
        //ie
        let sel = document.selection.createRange();
        sel.select();
        let selectStr = sel.text;
        sel.pasteHTML("<b>" + selectStr + "<b/>");
      }
    },
    onChangeOption(letter) {
      this.$emit("onChangeAnswer", letter);
      if (this.canEliminator) {
        let index = this.question.crossOutArr.indexOf(letter);
        if (index === -1) {
          this.question.crossOutArr.push(letter);
        }
        if (index > -1) {
          this.question.crossOutArr.splice(index, 1);
        }
      }
      if (this.canMasking) {
        let index = this.question.maskArr.indexOf(letter);
        if (index === -1) {
          this.question.maskArr.push(letter);
        }
        if (index > -1) {
          this.question.maskArr.splice(index, 1);
        }
      }
    }
  }
};
</script>

<style>
.highlight {
  background-color: #fae89d;
  border-bottom: 1px dashed #000;
  /* position: unset;
  display: inline-block; */
}
.highlight-span {
  position: unset;
  display: inline-block;
}
</style>
<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.title {
  padding-left: 1rem;
  margin-bottom: 1rem;
  border-left: 0.4rem solid var(--themeColor);
}
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}
.form-check {
  position: relative;
  min-height: 2rem;
}
.optionActionLine {
  position: absolute;
  display: block;
  height: 0.1rem;
  background: #000000;
  width: 104%;
  left: -2%;
}
.myquestion .form-check-input,
.myquestion .form-check-label {
  font-size: 14px;
  cursor: pointer;
}

.strike {
  text-decoration: line-through;
  color: #6c757d;
}
.form-check-hide {
  color: white;
}
.form-check-hide .strike {
  color: white;
}
::v-deep .sidebar-content,
::v-deep .sidebar-content p {
  word-wrap: break-word;
}

.question-Box {
  display: flex;
}
.question-Passage {
  flex: 1;
}
.question-Question {
  padding-left: 1rem;
}
@media (max-width: 768px) {
  .question-Box {
    display: block;
  }
  .question-Passage {
    flex: 1;
  }
  .question-Question {
    padding-left: 0;
  }
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>

<style scoped src="@/views/act/assets/css/simplebar.css"></style>
