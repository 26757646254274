var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.exam)?_c('Breadcrumb',{staticClass:"mt-4"},[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'ACTHistory' }}},[_vm._v(" ACT Exam History ")])],1),(_vm.exam)?_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'ACTResultPage',
            params: {
              userExamId: _vm.$route.params.userExamId
            }
          }}},[_vm._v(" "+_vm._s(_vm.exam.name)+" ")])],1):_vm._e(),(_vm.exam)?_c('el-breadcrumb-item',[_c('kbd',[_vm._v(_vm._s(_vm.questionOrder))])]):_vm._e(),_c('el-breadcrumb-item',[_vm._v(" Explanation ")])],1)],2):_vm._e(),_c('Heading',{staticClass:"mb-3",attrs:{"content":`${_vm.exam.name} - Explanation`}}),(_vm.exam)?_c('div',{staticClass:"question-title"},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.sectionOrder),callback:function ($$v) {_vm.sectionOrder=$$v},expression:"sectionOrder"}},_vm._l((_vm.practices),function(practice,practiceIndex){return _c('el-collapse-item',{key:practiceIndex,attrs:{"name":practiceIndex + 1}},[_c('template',{slot:"title"},[_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(practice.name)+" ")])]),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"showTags"},[_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Correct")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question wrong"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Wrong")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question empty"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Unanswered")])])]),_c('div',_vm._l((practice.exam_questions),function(title,index){return _c('span',{key:index,class:{
                view_question: true,
                wrong: title.answer ? title.is_correct == 0 : false,
                empty: title.answer === null || title.answer === ''
              },on:{"click":function($event){return _vm.viewBrowse(practiceIndex + 1, index + 1)}}},[(title.id == _vm.question.id)?_c('span',{staticClass:"currentIcon questionsOrderActive"},[_c('i',{staticClass:"fa-solid fa-location-dot"})]):_vm._e(),_vm._v(" "+_vm._s(index + 1)+" ")])}),0)])],2)}),1)],1):_vm._e(),_c('div',{staticClass:"pt-3"},[(_vm.question.question)?_c('div',{staticClass:"test-paper"},[_c('MultipleChoiceWithPassage',{ref:"MultipleChoiceWithPassage",attrs:{"id":_vm.questionOrder,"questionId":_vm.question.question.id,"testType":_vm.practice.name,"canHighlighter":false,"canEliminator":false,"canMasking":false,"passageContent":_vm.question.question.passage.content,"questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.question.answer,"tags":_vm.question.question.question_tags
            ? _vm.question.question.question_tags
            : [],"activities":_vm.question.metadata && _vm.question.metadata.activities
            ? _vm.question.metadata.activities
            : null,"isCorrect":_vm.question.is_correct,"correctAnswer":_vm.question.question.answers
            ? _vm.question.question.answers[0].answer[0]
            : '',"explanation":_vm.question.question && _vm.question.question.explanations
            ? _vm.question.question.explanations[0].explanation
            : ''},on:{"onChangeAnswer":_vm.onChangeAnswer}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }